<template>

<div class="p-grid">
    <div class="p-col-12">
      <Panel header="Acompanhamento de Log's" style="height: 100%">
        <Toolbar class="mb-1">
          <template #start>
            <ul>
              <li>
                <span class="product-badge status-post">POST</span>
                <b> - CADASTRO DE REGISTRO.</b>
              </li>
              <li class="mt-2">
                <span class="product-badge status-put">PUT</span>
                <b> - ALTERAÇÃO DE REGISTRO.</b>
              </li>
              <li class="mt-2">
                <span class="product-badge status-delete">DELETE</span>
                <b> - REMOÇÃO DE REGISTRO.</b>
              </li>

              <li class="mt-2">
                <span class="product-badge status-get">GET</span>
                <b> - LISTAGEM DE UM OU MAIS REGISTROS.</b>
              </li>
            </ul>
          </template>
          <template #end> </template>
        </Toolbar>
        <DataTable
          :value="allLog"
          :lazy="true"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 30]"
          scrollable
          v-model:filters="filters" 
          ref="dt"
          class="p-datatable-sm"
          dataKey="id"
          :totalRecords="totalRecords"
          :loading="loading"
          @page="onPage($event)"
          @filter="onFilter($event)" 
          filterDisplay="row" 
          responsiveLayout="scroll"
          :globalFilterFields="['description', 'operatorName', 'dtOperation', 'operation', 'endPoint']"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo de {first} a {last} no total de {totalRecords} registros de Log."
        >
          <template #empty>
            <div class="p-text-center">Nenhum resultado encontrado...</div>
          </template>
          <Column 
            header="End-Point" 
            field="endPoint"
            ref="endPoint"
            filterField="endPoint"
          >
            <template #filter="{ filterModel, filterCallback }">
              <Dropdown 
                v-model="filterModel.value"
                :options="allEndPoints" 
                :filter="true"
                placeholder="Informe o end-point" 
                @change="filterCallback()"
                >
              </Dropdown>
            </template>
            <template #body="slotProps">
              {{ slotProps.data.endPoint }}
            </template>
          </Column>
          <Column 
            header="Descrição" 
            field="description"
            ref="description"
            filterField="description">
           >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Informe a descrição" />
            </template>

            <template #body="slotProps">
              <div class="myBox">
                {{ slotProps.data.description }}
              </div>
            </template>
          </Column>
          <Column 
            header="Responsavél" 
            field="operatorName"
            ref="operatorName"
            filterField="operatorName"
          
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Informe o responsável" />
            </template>
            <template #body="slotProps">
              {{ slotProps.data.operatorName }}
            </template>
          </Column>
          <Column header="Origem" field="address">
            <template #body="slotProps">
              {{ formatIp(slotProps.data.address) }}
            </template>
          </Column>
          <Column 
            header="Dt. Operação" 
            field="dtOperation"
            ref="dtOperation" 
            filterField="dtOperation"
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="date" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Pesquisar por Data" />
            </template>
            <template #body="slotProps">
              {{ $DateTime.formatarDateTime24hours(slotProps.data.dtOperation) }}
            </template>
          </Column>

          <Column 
            header="Operação" 
            field="operation"
            ref="operation" 
            filterField="operation"
          
          >
            <template #filter="{ filterModel, filterCallback }">
              <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter"
                placeholder="Informe a operação" />
            </template>
            <template #body="slotProps">
              <span v-if="slotProps.data.operation === 'POST'" class="product-badge status-post">{{ slotProps.data.operation
              }}</span>
              <span v-if="slotProps.data.operation === 'PUT'" class="product-badge status-put">{{ slotProps.data.operation
              }}</span>
              <span v-if="slotProps.data.operation === 'DELETE'" class="product-badge status-delete">{{
                slotProps.data.operation }}</span>
              <span v-if="slotProps.data.operation === 'GET'" class="product-badge status-get">{{
                slotProps.data.operation }}</span>
            </template>
          </Column>
        </DataTable>
      </Panel>
    </div>
  </div>
</template>

<script>

import LogService from "../../service/log/log_service";

import Log from "../../models/log";

export default {
  data() {
    return {
      loading: false,
      log: new Log(),
      logService: new LogService(),
      allLog: [],
      allEndPoints: [],
      totalRecords: 0,
      filters: {
        description: { value: "", matchMode: "contains" },
        operatorName: { value: "", matchMode: "contains" },
        operation: { value: "", matchMode: "contains" },
        dtOperation: { value: "", matchMode: "contains" },
        endPoint: { value: "", matchMode: "contains" },
      },
    };
  },
  logService: null,
  datatableService: null,
  created() {
    this.loading = true;
    this.lazyParams = {
      first: 0,
      sortField: null,
      sortOrder: null,
      description: "",
      operatorName: "",
      operation: "",
      dtOperation: "",
      endPoint: "",
    };
    
  },
  mounted() {
    this.findAll();
    this.findEndPoints();
  },
  methods: {
    findAll() {
      this.loading = true;
      //LIMPEZA DE FILTROS
      this.lazyParams.description = this.lazyParams.description == null ? "" : this.lazyParams.description;
      this.lazyParams.operatorName = this.lazyParams.operatorName == null ? "" : this.lazyParams.operatorName;
      this.lazyParams.operation = this.lazyParams.operation == null ? "" : this.lazyParams.operation;
      this.lazyParams.dtOperation = this.lazyParams.dtOperation == null ? "" : this.lazyParams.dtOperation;
      this.lazyParams.endPoint = this.lazyParams.endPoint == null ? "" : this.lazyParams.endPoint;
      // FIM LIMPEZA DE FILTROS
      this.logService.findAll(this.lazyParams).then((data) => {
        this.allLog = data.content;
        this.totalRecords = data.totalElements;
        this.loading = false;
      });
    },
    findEndPoints() {
      this.logService
        .findByPoints()
        .then((data) => {
          this.allEndPoints = data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            this.$msgWarn(null);
          }
        });
    },
    formatIp(param) {
      let containing = param.indexOf(", tokenType=BearertokenValue=<TOKEN>");
      if (containing > -1) {
        param = param.replace(", tokenType=BearertokenValue=<TOKEN>", "");
      }
      return param;
    },
    
    onPage(event) {
      this.lazyParams.page = event.page;
      this.lazyParams.size = event.rows;
      this.findAll();
    },
    
    onFilter() {
      this.lazyParams.page = 0;
      this.lazyParams.description = this.filters.description.value;
      this.lazyParams.operatorName = this.filters.operatorName.value;
      this.lazyParams.operation = this.filters.operation.value;
      this.lazyParams.dtOperation = this.filters.dtOperation.value;
      this.lazyParams.endPoint = this.filters.endPoint.value;
      this.findAll();
    },
    
  },
};
</script>

<style lang="scss" scoped>
.myBox {
  border: none;
  height: 40px;
  width: 250px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: scroll;
}
.product-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-get {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-post {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-put {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-delete {
    background: #ffcdd2;
    color: #c63737;
  }
}
</style>